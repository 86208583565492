import {gql} from "@apollo/client";

export const FETCH_POPULAR = gql`
    query fetchPopular {
        movies: popularMovies {
            id
            name
            overview
            releaseDate
            score
            img: poster {
                url: custom(size: "w185_and_h278_bestv2")
            }
        }
    }
`;

export const SEARCH_MOVIES = gql`
    query SearchMovies($query: String!) {
        searchMovies(query: $query) {
            id
            name
            overview
            releaseDate
            score
            img: poster {
                url: custom(size: "w185_and_h278_bestv2")
            }
        }
    }
`;

export const GET_MOVIE = gql`
    query getMovie($id: ID!) {
        movie(id: $id) {
            id
            name
            overview
            releaseDate
            score
            socialMedia {
                imdb
            }
            img: poster {
                url: custom(size: "w600_and_h900_bestv2")
            }
            similar {
                id
                name
                overview
                releaseDate
                score
                img: poster {
                    url: custom(size: "w600_and_h900_bestv2")
                }
            }
            cast(limit: 5) {
                id
                person {
                    name
                    photo {original, small}
                }
                role {
                    ... on Cast {
                        character
                    }
                }
            }
        }
    }
`;