import DisplayPopular from "../components/DisplayPopular";
import {
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import React from "react";
import {Search} from "@mui/icons-material";
import SearchResult from "../components/SearchResult";
import Typography from "@mui/material/Typography";

export default function Home() {
    const [search, setSearch] = React.useState("");
    const [searched, setSearched] = React.useState("");

    const handleSearch = (event) => {
        setSearched(search);
    }

    const searchKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(event);
        }
    }

    return (
        <div>
            <Container>
                <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
                    <InputLabel htmlFor="search-input">Search</InputLabel>
                    <OutlinedInput
                        id="search-input"
                        value={search}
                        onKeyDown={searchKeyDown}
                        onChange={(e) => setSearch(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle visibility"
                                    onClick={handleSearch}
                                    edge="end"
                                >
                                    <Search/>
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Search"
                    />
                </FormControl>

                {
                    searched !== "" && <>
                        <Typography align="center" sx={{ mt: 6 }} variant="h4" component="div">
                            Search results
                        </Typography>
                        <SearchResult search={searched}/>
                    </>
                }

                <Typography align="center" sx={{ mt: 6 }} variant="h4" component="div">
                    Populars Movies
                </Typography>

                <DisplayPopular/>
            </Container>
        </div>
    );
};