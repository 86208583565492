import {Link, Outlet, useNavigate} from "react-router-dom";
import {AppBar, Box, Button, IconButton, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import {HomeOutlined} from "@mui/icons-material";

const Layout = () => {
    let navigate = useNavigate();

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar>
                        <IconButton
                            onClick={() => {navigate("/")}}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2}}
                        >
                            <HomeOutlined/>
                        </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        ApexMovie
                    </Typography>
                </Toolbar>
            </AppBar>

            <Outlet/>
        </Box>
    )
};

export default Layout;