import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Box, CardActionArea, Rating} from '@mui/material';
import {useNavigate} from "react-router-dom";

export default function MovieCard({movie}) {

    let navigate = useNavigate();

    return (
        <Card sx={{maxWidth: 345}}>
            <CardActionArea onClick={() => navigate('/movie/' + movie.id)}>
                <CardMedia
                    component="img"
                    height="140"
                    image={movie.img && movie.img.url ? movie.img.url : "https://via.placeholder.com/300?text=Image+not+available"}
                    alt={movie.name}
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        {movie.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                        {movie.overview}
                    </Typography>
                    <Box
                        sx={{
                            width: 200,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Rating name="read-only" value={movie.score / (10 / 5)} precision={0.5}
                                readOnly/>
                        <Box sx={{mt: 1, ml: 1}}>{Math.round(movie.score * 10) / 10}</Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}