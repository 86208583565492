import {useQuery} from "@apollo/client";
import {FETCH_POPULAR} from "../queries";
import MovieCard from "./MovieCard";
import {Box, CircularProgress, Grid, Stack} from "@mui/material";

export default function DisplayPopular() {
    const {loading, error, data} = useQuery(FETCH_POPULAR);

    if (loading) return <Stack alignItems="center">
        <CircularProgress/>
    </Stack>;

    if (error) return <p>Error :(</p>;

    return <Box sx={{flexGrow: 1, mt: 6}}>
        <Grid container spacing={3}>
            {data.movies.map((movie) => (
                <Grid key={movie.id} item xs={6} sm={6} md={4} lg={3}>
                    <MovieCard movie={movie}/>
                </Grid>
            ))}
        </Grid>
    </Box>;
}