import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_MOVIE} from "../queries";
import {
    Avatar,
    Box,
    CircularProgress,
    Container,
    Grid,
    Rating,
    Stack,
    Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import * as React from "react";
import {useEffect} from "react";
import MovieCard from "../components/MovieCard";


const Movie = () => {
    const params = useParams();

    const {loading, error, data} = useQuery(GET_MOVIE, {
        variables: {id: params.id},
    });

    const [wiki, setWiki] = React.useState(null);
    const [wikiDescription, setWikiDescription] = React.useState(null);

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`https://en.wikipedia.org/w/api.php?format=json&action=query&titles=${data.movie.name}&prop=revisions&rvprop=content&origin=*`)
            const responseData = await response.json();

            const wikiDetails = responseData.query.pages[Object.keys(responseData.query.pages)[0]].revisions[0]['*']

            setWiki(wikiDetails);

            const regex = new RegExp('short description\\|(.*)}}', 'mgi')
            let match = regex.exec(wikiDetails);
            setWikiDescription(match ? match[1] : '');
        }

        if (!data || !data.movie)
            return;

        fetchData();
    }, [data]);

    if (loading) return <Stack alignItems="center">
        <CircularProgress/>
    </Stack>;

    if (error) {
        console.error(error)
        return <p>Error :(</p>;
    }

    return (
        <Container>
            <Card>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="600"
                    image={data.movie.img && data.movie.img.url ? data.movie.img.url : "https://via.placeholder.com/600?text=Image+not+available"}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {data.movie.name}

                        <Box
                            sx={{
                                width: 200,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Rating name="read-only" value={data.movie.score / (10 / 5)}
                                    precision={0.5}
                                    readOnly/>
                            <Box sx={{mt: 1, ml: 1}}>{Math.round(data.movie.score * 10) / 10}</Box>
                        </Box>

                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {data.movie.overview}
                    </Typography>

                    <Typography gutterBottom variant="h5" component="div">
                        Cast
                    </Typography>

                    <Stack direction="row" spacing={2}>
                        {data.movie.cast.map((actor) => (
                            <Tooltip placement="top"
                                     title={actor.person.name + " - " + actor.role.character}
                                     key={actor.id}>
                                <Avatar alt={actor.person.name}
                                        src={actor.person.photo && actor.person.photo ? actor.person.photo.small : "https://via.placeholder.com/200?text=" + this.person.name}/>
                            </Tooltip>
                        ))}
                    </Stack>

                    {wiki ? <>
                        <Typography gutterBottom variant="h5" sx={{mt: 2}} component="div">
                            {wikiDescription ? <a target="_blank" rel="noreferrer"
                                                  href={`https://en.wikipedia.org/wiki/${data.movie.name}`}>Wikipedia</a> : 'Wikipedia'}
                        </Typography>

                        <Typography variant="body2" color="text.secondary" component="div">
                            {/*<div dangerouslySetInnerHTML={{__html: wiki }}></div>*/}
                            {wikiDescription ? wikiDescription : 'No short description available'}
                        </Typography>
                    </> : ''}

                    <Typography gutterBottom variant="h5" sx={{mt: 2}} component="div">
                        {data.movie.socialMedia.imdb ?
                            <a target="_blank" rel="noreferrer" href={data.movie.socialMedia.imdb}>Find
                                out more about {data.movie.name} on
                                IMDb.</a> : 'No IMDb link available'}
                    </Typography>

                    <Typography align="center" sx={{mt: 6}} variant="h4" component="div">
                        Similar Movies
                    </Typography>

                    <Box sx={{flexGrow: 1, mt: 6}}>
                        <Grid container spacing={3}>
                            {data.movie.similar.map((movie) => (
                                <Grid key={movie.id} item xs={6} sm={6} md={4} lg={3}>
                                    <MovieCard movie={movie}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                </CardContent>
            </Card>
        </Container>
    )
        ;
};

export default Movie;